import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as style from "../styles/privacypolicy.module.scss"

const Privacypolicy = () => {
  return (
    <Layout>
      <Seo
        title="職種紹介 | Recruit | 株式会社アローズ"
        description="進化する、持続可能なビジネスへの挑戦。"
      />
      <h1>
        PRIVACY POLICY<p>プライバシーポリシー</p>
      </h1>
      <section className={style.privacy}>
        <p>
          株式会社アローズ(以下、当社)は、経営理念と自ら定めた行動規範に基づき、社会に貢献し、すべてのお客様から信頼を得るとともに、当社へ
          の期待に応えるべく事業活動を進めています。
          <br />
          当社が事業活動を通してステークホルダーの皆様から取得した個人情報は、皆様の大切な財産であるとともに、当社にとって新たな価値
          創造の源泉となる重要資産であることを認識して、個人情報の保護を以下の基本方針に従って適切に行います。
        </p>
        <h2>1.基本方針</h2>
        <p>
          当社は個人情報保護法及び関連するその他の法令・規範を尊守します。また当社は「丸隆個人情報保護プログラム(以下、当社
          規定)を制定し、個人情報保護マネジメントシステムを着実に実施し、維持するとともに、継続的な改善に努めます。
          <br />
          当社は、個人情報保護に関する管理体制を確立するとともに、当社規定を役員および従業員に周知し、その尊種徹底に努めます。
          <br />
          当社は、個人情報をお客様に明示した利用目的の範囲内で取扱います。また、当社はお客様からご提供いただいた個人情報を、お客様の同意がある場合または正当な理由がある場合を除き、第三者に開示または提供しません。
          <br />
          当社は、個人情報を正確かつ最新の状態に保つとともに、個人情報への不正アクセス、個人情報の漏えい、滅失、き損等の予防に努め、情報セキュリティ上の向上、是正を継続的に実施します。
        </p>
        <h2>2.個人情報の利用目的</h2>
        <p>
          当社は、お客様から個人情報をご提供いただく場合、あらかじめ個人情報の利用目的を明示し、その利用目的の範囲内で利用します。
          <br />
          あらかじめ明示した利用目的の範囲を超えて、お客様の個人情報を利用する必要が生じた場合は、お客様にその旨をご連絡し、お客様の同意をいただいた上で利用します。
        </p>
        <h2>3.個人情報の提供</h2>
        <p>
          1.当社は、次の場合を除き、お客様の個人情報を第三者に開示または提供しません。
          <br />
          <dl>
            <dt>１）</dt>
            <dd>お客様の同意がある場合</dd>
            <dt>２）</dt>
            <dd>法令に基づく場合</dd>
            <dt>３）</dt>
            <dd>
              人の生命、身体または財産保護のために必要であって、お客様の同意を取ることが困難な場合
            </dd>
            <dt>４）</dt>
            <dd>
              利用目的の達成に必要な範囲内で、個人情報の取り扱いを委託する場合
            </dd>
            <dt>５）</dt>
            <dd>
              合併、会社分割、営業譲渡その他の理由によって事由によって事業の承継が行われる場合
            </dd>
          </dl>
          2.当社は、上記（１）にかかわらず、お客様からの商品や修理・サービスに関するお問い合わせに対し、当社の関係会社や代理店より対応させていただくことが適切と判断される場合に、お客様の住所、氏名、電話番号を当該関係者等に提供することがあります。
          <br />
          この場合、お客様は当社に対し当該関係会社等への個人情報提供の停止を請求することができます。
        </p>
        <h2>4.開示等の請求手続き</h2>
        <p>
          当社は、保有するお客様ご自身の個人情報について、
          <br />
          <dl>
            <dt>１）</dt>
            <dd>開示の請求</dd>
            <dt>２）</dt>
            <dd>利用目的の通知の請求</dd>
            <dt>３）</dt>
            <dd>訂正の請求</dd>
            <dt>４）</dt>
            <dd>追加の請求</dd>
            <dt>５）</dt>
            <dd>消去の請求</dd>
            <dt>６）</dt>
            <dd>
              利用も停止または第三者提供の請求に対応させていただいております。
            </dd>
          </dl>
          なお、開示の請求、利用目的の通知の請求につきましては
          有料とさせていただきます。
        </p>
        <h2>5.個人情報に関するお問い合わせ</h2>
        <p>
          お客様ご自身の個人情報についてお問い合わせは、弊社０３－５３６５－３８３８までお問い合わせください。
        </p>
        <h2>6.その他の事項</h2>
        <p>
          1.本「個人情報保護方針」は、株式会社アローズの日本国内における個人情報の取り扱いに関するものです。
          <br />
          2.16歳未満のお客様は、保護者の方の同意を得た上で、個人情報のご提供を頂きますようお願いします。
          <br />
          3.当社のウエブサイトにリンクしている他者のウエブサイトにおけるお客様の個人情報の安全確保については、当社が責任を
          追うことはできません。
          <br />
          4.重要な変更やお知らせ事項がある場合には、当ページにてお知らせします。
          <br />
          5.当社では、より良くお客様の個人情報の保護を図るために、または、関係法令の変更に伴い、個人情報保護方針を改定することが
          あります。
        </p>
      </section>
    </Layout>
  )
}

export default Privacypolicy
